import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from 'src/components/Layout';
import { HomeQuery } from 'src/graphqlTypes';

interface HomeProps {
    data: HomeQuery;
}

const Home: FC<HomeProps> = ({ data }) => {
    return (
        <Layout>
            {data.allContentfulShark.nodes.map((node) => (
                <div key={node.id}>
                    <div>{node.name}</div>
                </div>
            ))}
        </Layout>
    );
};

export const query = graphql`
    query Home {
        allContentfulShark {
            nodes {
                id
                name
            }
        }
    }
`;

export default Home;
